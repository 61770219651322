
import Vue, { PropType } from 'vue';
import CheckMark from '@/views/messages/CheckMark.vue';
import { DetailsThread } from '../messaging.model';

export default Vue.extend({
  name: 'DetailsChatEditor',
  components: { CheckMark },
  props: {
    attachedFileName: { type: String, default: null },
    isSaving: { type: Boolean, required: true },
    isSavingSuccess: { type: Boolean, required: true },
    messageText: { type: String, required: true },
    onFileDragHover: { type: Function as PropType<(evt: DragEvent) => void>, required: true },
    onFileSelectHandler: { type: Function as PropType<(evt: DragEvent) => void>, required: true },
    onResetAttachedFile: { type: Function as PropType<() => void>, required: true },
    onSendMessage: { type: Function as PropType<() => void>, required: true },
    onUpdateMessageText: { type: Function as PropType<() => void>, required: true },
    selectedThread: { type: Object as PropType<DetailsThread>, required: true },
  },
  methods: {
    onBrowseFile() {
      (this.$refs.filenewRef as HTMLInputElement).click();
    },
  },
});
