
import Vue, { PropType } from 'vue';
import DAvatar from '@/components/DAvatar.vue';
import { Chat } from '../messaging.model';

export interface DetailsChatProps {
  groupedMessagesCount: number;
  index: number;
  message: Chat;
}

export default Vue.extend({
  name: 'DetailsChat',
  components: { DAvatar },
  props: {
    detailsChatProps: { type: Object as PropType<DetailsChatProps>, required: true },
    onClickAttachedFile: { type: Function as PropType<(id: number) => void>, required: true },
    showPdf: { type: Boolean, required: true },
  },
  data: () => ({
    sizeScreenStyle: '',
  }),
  mounted() {
    this.onResize();

    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },
  methods: {
    onResize() {
      this.sizeScreenStyle =
        null !== document.querySelector('.messages-right')
          ? 'width: ' + (document.querySelector('.messages-right').clientWidth - 100) + 'px'
          : '';
    },
    addLinks(text: string) {
      const urlExpReg = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/gi;
      const emailPattern = /[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}/gi;
      let textReplaced = text.replace(
        emailPattern,
        matchedStr => `<a target="_blank" href=mailto:"${matchedStr}">${matchedStr}</a>`
      );

      textReplaced = textReplaced.replace(
        urlExpReg,
        matchedUrl => `<a target="_blank" href="${matchedUrl}">${matchedUrl}</a>`
      );

      return textReplaced;
    },
  },
});
