
import Vue, { PropType } from 'vue';
import { ThreadsPanel, MenuItem, ScrollSettings } from './components';
import { SummaryMessage, DetailsThread, OwnUser } from './messaging.model';
import { Recipient } from './components/ThreadsToolbarMessageDialogItemList.vue';

const splitConfig = {
  foldToolbar: 550,
};

export default Vue.extend({
  name: 'MessagingComponent',
  components: {
    ThreadsPanel,
    DetailsPanel: () =>
      import(/* webpackChunkName: "messaging", webpackPrefetch: true */ './components/DetailsPanel.vue'),
    DetailsChatPdf: () =>
      import(/* webpackChunkName: "messaging", webpackPrefetch: true */ './components/DetailsChatPDF.vue'),
  },
  props: {
    attachedFileName: { type: String, default: null },
    groupedMessagesCount: { type: Number, required: true },
    isGroupedMessages: { type: Boolean, required: true },
    isLoadingThreadById: { type: Boolean, required: true },
    isSaving: { type: Boolean, required: true },
    isSavingSuccess: { type: Boolean, required: true },
    isThreadsPanelLoading: { type: Boolean, required: true },
    isVisibleDialogNewThread: { type: Boolean, required: true },
    messageText: { type: String, required: true },
    onChangeSearchText: { type: Function as PropType<(valueObject: string) => void>, required: true },
    onChangeSelectedItem: { type: Function as PropType<(itemObject: number) => void>, required: true },
    onChangeStatusFilter: { type: Function as PropType<(itemObject: MenuItem) => void>, required: true },
    onClickAttachedFile: { type: Function as PropType<(idObject: number) => void>, required: true },
    onClosePanelRight: { type: Function as PropType<() => void>, required: true },
    onCloseThread: { type: Function as PropType<() => void>, required: true },
    onCreateChat: {
      type: Function as PropType<
        (recipientId: number, subjectObject: string, specialtySelected: number, rol: string) => void
      >,
      required: true,
    },
    onDownloadPDF: { type: Function as PropType<(value: boolean, elObject?: Element) => void>, required: true },
    onFileDragHover: { type: Function as PropType<(evtObject: DragEvent) => void>, required: true },
    onFileSelectHandler: { type: Function as PropType<(evtObject: DragEvent) => void>, required: true },
    onLoadMessageThreadById: { type: Function as PropType<(id: number) => void>, required: true },
    onOpenUrl: { type: Function as PropType<(fileObject: File) => void>, required: true },
    onReadThreadById: { type: Function as PropType<(threadIdObject: number) => void>, required: true },
    onResetAttachedFile: { type: Function as PropType<() => void>, required: true },
    onResetStatusFilter: { type: Function as PropType<() => void>, required: true },
    onSendMessage: { type: Function as PropType<() => void>, required: true },
    onShowNewThreadDialog: { type: Function as PropType<(visibleObject: boolean) => void>, required: true },
    onUnfoldThread: { type: Function as PropType<() => void>, required: true },
    onUpdateMessageText: { type: Function as PropType<() => void>, required: true },
    optionsStatusFilter: { type: Array as PropType<MenuItem[]>, required: true },
    ownUser: { type: Object as PropType<OwnUser>, required: true, default: {} },
    recipientsOptions: { type: Array as PropType<Recipient[]>, required: true },
    scrollSettings: { type: Object as PropType<ScrollSettings>, required: true, default: {} },
    searchText: { type: String, default: '' },
    selectedItem: { type: Number, default: null },
    selectedStatusFilter: { type: Object as PropType<MenuItem>, default: null },
    selectedThread: { type: Object as PropType<DetailsThread>, required: true },
    showMessages: { type: Boolean, required: true },
    showPdf: { type: Boolean, required: true },
    showPoweredByDocline: { type: Boolean, required: true },
    showSplitAreaLeft: { type: Boolean, required: true },
    summaryThreads: { type: Array as PropType<SummaryMessage[]>, required: true },
    specialties: { type: Array, required: true },
    messagingPermission: { type: Boolean, required: true },
    meta: { type: Object, required: true },
    onChangePage: { type: Function as PropType<(page: number) => void>, required: true },
    onResetSearch: { type: Function as PropType<() => void>, required: true },
  },
  data() {
    return {
      fullScreen: false,
      foldToolbar: false,
    };
  },
  computed: {
    appNavHeight(): number {
      return this.$vuetify.breakpoint.mobile ? 56 : 64;
    },
    gutterSize(): number {
      return this.showMessages && this.showSplitAreaLeft ? 8 : 0;
    },
    splitAreaLeftSize(): number {
      return this.showMessages ? 48 : 100;
    },
    splitAreaRightSize(): number {
      return !this.showSplitAreaLeft ? 100 : 52;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.foldToolbar = (this.$refs.threadPanelRef as any).$el.offsetWidth <= splitConfig.foldToolbar;
    });
  },
  methods: {
    onDragEnd() {
      this.foldToolbar = (this.$refs.threadPanelRef as any).$el.offsetWidth <= splitConfig.foldToolbar;
    },
  },
});
