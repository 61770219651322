
import Vue, { PropOptions } from 'vue';
import DAvatar from '@/components/DAvatar.vue';
import { SummaryMessage } from '../messaging.model';

export default Vue.extend({
  name: 'ThreadsItem',
  components: { DAvatar },
  props: {
    messageThread: { type: Object, required: true } as PropOptions<SummaryMessage>,
    onLoadMessageThreadById: { type: Function, required: true } as PropOptions<
      (id: SummaryMessage['idThread']) => void
    >,
    onReadThreadById: { type: Function, required: true } as PropOptions<(threadId: number) => void>,
  },
  computed: {
    computedStatus(): string {
      return this.messageThread.status === 'open'
        ? this.$t('mensajeria.abiertos').toString()
        : this.messageThread.status === 'close'
        ? this.$t('mensajeria.cerrados').toString()
        : this.$t('mensajeria.pendientes').toString();
    },
  },
  methods: {
    async handleOnClick() {
      await this.onLoadMessageThreadById(this.messageThread.idThread);
      await this.onReadThreadById(this.messageThread.idThread);
    },
  },
});
