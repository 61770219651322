
import Vue, { PropType } from 'vue';
import { AttachedFile, DetailsThread, Chat, OwnUser } from '../messaging.model';
import DetailsChat, { DetailsChatProps } from './DetailsChat.vue';
import DetailsChatEditor from './DetailsChatEditor.vue';
import DetailsContactInfo, { DetailsContactInfoProps } from './DetailsContactInfo.vue';
import DetailsMessagesGrouped, { DetailsMessagesGroupedProps } from './DetailsMessagesGrouped.vue';
import DetailsPanelBridge from './DetailsPanelBridge.vue';
import DetailsToolbar, { DetailsToolbarProps } from './DetailsToolbar.vue';
import { ScrollSettings } from './threads-panel.model';

export default Vue.extend({
  name: 'DetailsPanel',
  components: {
    DetailsChat,
    DetailsChatEditor,
    DetailsContactInfo,
    DetailsMessagesGrouped,
    DetailsPanelBridge,
    DetailsToolbar,
  },
  props: {
    attachedFileName: { type: String, default: null },
    groupedMessagesCount: { type: Number as PropType<number>, required: true },
    isGroupedMessages: { type: Boolean, required: true },
    isLoadingThreadById: { type: Boolean, required: true },
    isSaving: { type: Boolean, required: true },
    isSavingSuccess: { type: Boolean, required: true },
    messageText: { type: String, required: true },
    onClickAttachedFile: { type: Function as PropType<(id: number) => void>, required: true },
    onClosePanelRight: { type: Function as PropType<() => void>, required: true },
    onCloseThread: { type: Function as PropType<() => void>, required: true },
    onDownloadPDF: { type: Function as PropType<(value: boolean, el?: Element) => void>, required: true },
    onFileDragHover: { type: Function as PropType<(evt: DragEvent) => void>, required: true },
    onFileSelectHandler: { type: Function as PropType<(evt: DragEvent) => void>, required: true },
    onOpenUrl: { type: Function as PropType<(file: AttachedFile) => void>, required: true },
    onResetAttachedFile: { type: Function as PropType<() => void>, required: true },
    onSendMessage: { type: Function as PropType<() => void>, required: true },
    onUnfoldThread: { type: Function as PropType<() => void>, required: true },
    onUpdateMessageText: { type: Function as PropType<() => void>, required: true },
    ownUser: { type: Object as PropType<OwnUser>, required: true },
    scrollSettings: { type: Object as PropType<ScrollSettings>, required: true },
    selectedThread: { type: Object as PropType<DetailsThread>, required: true },
    showPdf: { type: Boolean, required: true },
  },
  computed: {
    isThreadClose(): boolean {
      return this.selectedThread.status === 'close';
    },
    toolbarProps(): DetailsToolbarProps {
      return {
        attachedFiles: this.selectedThread.attachedFiles,
        attachedFilesCount: this.selectedThread.attachedFiles.length,
        subject: this.selectedThread.subject,
        isClose: this.selectedThread.status === 'close',
      };
    },
    contactInfoProps(): DetailsContactInfoProps {
      return {
        name: this.selectedThread.anotherUser?.name,
        image: this.selectedThread.anotherUser?.image,
        collegiateNumber: this.selectedThread.anotherUser?.collegiateNumber || '',
        specialties: this.selectedThread.anotherUser?.specialties || [],
      };
    },
    detailsMessagesGroupedProps(): DetailsMessagesGroupedProps {
      return {
        name: this.selectedThread.anotherUser?.name,
        messagesCount: this.selectedThread.messages.length,
        groupedMessagesCount: this.groupedMessagesCount,
        isGroupedMessages: this.isGroupedMessages,
      };
    },
  },
  watch: {
    selectedThread() {
      setTimeout(() => {
        this.scrollToLastMessage();
      }, 500);
    },
  },
  methods: {
    detailsChatProps(message: Chat, index: number): DetailsChatProps {
      if (message) {
        return {
          groupedMessagesCount: this.groupedMessagesCount,
          message,
          index,
        };
      }

      return {
        groupedMessagesCount: 0,
        message: null,
        index: null,
      };
    },

    scrollToLastMessage() {
      const el = this.$el.getElementsByClassName('lastMessage')[0];

      if (el) {
        el.scrollIntoView();
      }
    },
  },
});
