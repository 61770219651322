
import Vue, { PropType } from 'vue';
import DAvatar from '@/components/DAvatar.vue';
import { RolUser } from '../messaging.model';

export interface Recipient {
  id: number;
  image: string;
  name: string;
  rol: RolUser;
  specialties: string[];
  title: string;
}

export default Vue.extend({
  name: 'ThreadsToolbarMessageDialogItemList',
  components: { DAvatar },
  props: {
    recipient: { type: Object as PropType<Recipient>, required: true },
    onlyPatients: { type: Boolean, required: true },
  },
  computed: {
    subtitle(): string {
      return this.recipient.rol === 'patient'
        ? this.$t('common.paciente').toString()
        : !!this.recipient.specialties && this.recipient.specialties.join(', ');
    },
  },
});
