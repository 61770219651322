
import Vue, { PropType } from 'vue';
import ThreadsToolbarMessageDialogItemList, { Recipient } from './ThreadsToolbarMessageDialogItemList.vue';

export default Vue.extend({
  name: 'ThreadsToolbarMessageDialog',
  components: { ThreadsToolbarMessageDialogItemList },
  props: {
    isVisible: { type: Boolean, required: true },
    onCloseDialog: { type: Function as PropType<() => void>, required: true },
    onCreateChat: {
      type: Function as PropType<
        (recipientId: number, subject: string, specialtySelected: number, rol: string) => void
      >,
      required: true,
    },
    recipientsOptions: { type: Array as PropType<Recipient[]>, required: true },
    specialties: { type: Array, required: true },
  },
  data() {
    return {
      selected: '',
      subject: '',
      specialtySelected: null as number,
    };
  },
  computed: {
    requiredFields(): boolean {
      return this.selected && this.subject !== '';
    },
    onlyPatients(): boolean {
      return this.recipientsOptions.every(recipient => recipient.rol === 'patient');
    },
  },
  methods: {
    handleOnCreateChat: function() {
      let rolSelected = '';
      const correctedRecipients = this.correctRecipientsOptions();
      correctedRecipients.forEach(item => {
        if (item.fakeId === this.selected) {
          rolSelected = item.rol;
        }
      });
      const idSelected = this.selected.replace('patient-', '').replace('professional-', '');
      this.onCreateChat(parseInt(idSelected), this.subject, this.specialtySelected, rolSelected);
      this.onCloseDialog();
    },
    correctRecipientsOptions() {
      const correctedRecipientsOptions = [];
      this.recipientsOptions.forEach(item => {
        item['fakeId'] = item.rol + '-' + item.id;
        correctedRecipientsOptions.push(item);
      });
      return correctedRecipientsOptions;
    },
  },
});
