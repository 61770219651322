
import Vue, { PropType } from 'vue';
import { MenuItem } from './threads-panel.model';
import ThreadsToolbarMessageDialog from './ThreadsToolbarMessageDialog.vue';
import { Recipient } from './ThreadsToolbarMessageDialogItemList.vue';

type FilterIcon = 'empty' | 'fill' | 'open';

export default Vue.extend({
  name: 'ThreadsToolbar',
  components: { ThreadsToolbarMessageDialog },
  props: {
    foldToolbar: { type: Boolean, required: true },
    isVisibleDialogNewThread: { type: Boolean, required: true },
    onChangeSearchText: { type: Function as PropType<(value: string) => void>, required: true },
    onChangeStatusFilter: { type: Function as PropType<(item: MenuItem) => void>, required: true },
    onCreateChat: {
      type: Function as PropType<
        (recipientId: number, subject: string, specialtySelected: number, rol: string) => void
      >,
      required: true,
    },
    onShowNewThreadDialog: { type: Function as PropType<(visible: boolean) => void>, required: true },
    optionsStatusFilter: { type: Array as PropType<MenuItem[]>, required: true },
    recipientsOptions: { type: Array as PropType<Recipient[]>, required: true },
    searchText: { type: String, default: '' },
    selectedStatusFilter: { type: Object as PropType<MenuItem>, default: null },
    specialties: { type: Array, required: true },
    messagingPermission: { type: Boolean, required: true },
    onResetSearch: { type: Function as PropType<() => void>, required: true },
  },
  data() {
    return {
      showSearchInput: false,
      filterIcon: {
        open: 'mdi-filter-outline',
        empty: 'mdi-filter-remove-outline',
        fill: 'mdi-filter-remove',
      },
    };
  },
  computed: {
    isMobile(): boolean {
      return this.$vuetify.breakpoint.mobile;
    },
    filterIconByState(): string {
      const state: FilterIcon = this.searchText ? 'fill' : this.showSearchInput ? 'empty' : 'open';
      return this.filterIcon[state];
    },
  },
  methods: {
    onCloseDialog() {
      this.onShowNewThreadDialog(false);
    },
    onBlur() {
      if (!this.searchText || this.searchText.length === 0) this.showSearchInput = false;
    },
    onClick() {
      this.showSearchInput = !this.showSearchInput;
      this.showSearchInput ? (this.$refs.searchTextRef as any).focus() : this.onChangeSearchText('');
    },
    resetSearch() {
      this.onResetSearch();
    },
  },
});
