
import Vue, { PropOptions } from 'vue';
import DAvatar from '@/components/DAvatar.vue';

export interface DetailsContactInfoProps {
  name: string;
  image?: string;
  collegiateNumber?: string;
  specialties?: string[];
}

export default Vue.extend({
  name: 'DetailsContactInfo',
  components: { DAvatar },
  props: {
    contactInfoProps: { required: true } as PropOptions<DetailsContactInfoProps>,
  },
});
