var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('split',{style:('height:calc(100vh - ' + _vm.appNavHeight + 'px)'),attrs:{"gutter-size":_vm.gutterSize},on:{"onDragEnd":_vm.onDragEnd}},[_c('split-area',{directives:[{name:"show",rawName:"v-show",value:(_vm.showSplitAreaLeft),expression:"showSplitAreaLeft"}],attrs:{"size":_vm.splitAreaLeftSize,"min-size":500}},[_c('threads-panel',_vm._b({ref:"threadPanelRef"},'threads-panel',{
          appNavHeight: _vm.appNavHeight,
          foldToolbar: _vm.foldToolbar,
          isThreadsPanelLoading: _vm.isThreadsPanelLoading,
          isVisibleDialogNewThread: _vm.isVisibleDialogNewThread,
          onChangeSearchText: _vm.onChangeSearchText,
          onChangeSelectedItem: _vm.onChangeSelectedItem,
          onChangeStatusFilter: _vm.onChangeStatusFilter,
          onCreateChat: _vm.onCreateChat,
          onLoadMessageThreadById: _vm.onLoadMessageThreadById,
          onReadThreadById: _vm.onReadThreadById,
          onResetStatusFilter: _vm.onResetStatusFilter,
          onShowNewThreadDialog: _vm.onShowNewThreadDialog,
          optionsStatusFilter: _vm.optionsStatusFilter,
          recipientsOptions: _vm.recipientsOptions,
          scrollSettings: _vm.scrollSettings,
          searchText: _vm.searchText,
          selectedItem: _vm.selectedItem,
          selectedStatusFilter: _vm.selectedStatusFilter,
          showPoweredByDocline: _vm.showPoweredByDocline,
          showSplitAreaLeft: _vm.showSplitAreaLeft,
          splitAreaLeftSize: _vm.splitAreaLeftSize,
          summaryThreads: _vm.summaryThreads,
          specialties: _vm.specialties,
          messagingPermission: _vm.messagingPermission,
          meta: _vm.meta,
          onChangePage: _vm.onChangePage,
          onResetSearch: _vm.onResetSearch,
        },false))],1),_c('split-area',{directives:[{name:"show",rawName:"v-show",value:(_vm.showMessages),expression:"showMessages"}],staticClass:"messages-right",attrs:{"size":_vm.splitAreaRightSize,"min-size":300},on:{"dragover":_vm.onFileDragHover,"dragleave":_vm.onFileDragHover,"drop":_vm.onFileSelectHandler}},[(_vm.selectedThread)?_c('details-panel',_vm._b({},'details-panel',{
          attachedFileName: _vm.attachedFileName,
          groupedMessagesCount: _vm.groupedMessagesCount,
          isGroupedMessages: _vm.isGroupedMessages,
          isLoadingThreadById: _vm.isLoadingThreadById,
          isSaving: _vm.isSaving,
          isSavingSuccess: _vm.isSavingSuccess,
          messageText: _vm.messageText,
          onClickAttachedFile: _vm.onClickAttachedFile,
          onClosePanelRight: _vm.onClosePanelRight,
          onCloseThread: _vm.onCloseThread,
          onDownloadPDF: _vm.onDownloadPDF,
          onFileDragHover: _vm.onFileDragHover,
          onFileSelectHandler: _vm.onFileSelectHandler,
          onOpenUrl: _vm.onOpenUrl,
          onResetAttachedFile: _vm.onResetAttachedFile,
          onSendMessage: _vm.onSendMessage,
          onUnfoldThread: _vm.onUnfoldThread,
          onUpdateMessageText: _vm.onUpdateMessageText,
          ownUser: _vm.ownUser,
          scrollSettings: _vm.scrollSettings,
          selectedThread: _vm.selectedThread,
          showPdf: _vm.showPdf,
        },false)):_vm._e()],1)],1),(_vm.showPdf)?_c('details-chat-pdf',{attrs:{"on-download-p-d-f":_vm.onDownloadPDF,"own-user":_vm.ownUser,"selected-thread":_vm.selectedThread}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }