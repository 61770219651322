import { AuthUser } from '@/services/auth-user';
import { localStorageService, LOCAL_STORAGE_KEYS } from '@/services/local-storage';

export const getHeader = (): HeadersInit => {
  const tokenData = localStorageService.getItem<AuthUser>(LOCAL_STORAGE_KEYS.AUTH_EHEALTH);
  const token = tokenData !== null && typeof tokenData.access_token !== 'undefined' ? tokenData.access_token : '';
  const headers = {
    Accept: 'application/json',
    Authorization: 'Bearer ' + token,
  };

  return headers;
};
