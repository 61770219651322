
import Vue, { PropOptions } from 'vue';
import { MIN_UNGROUPED_MESSAGES } from '../messaging.model';

export interface DetailsMessagesGroupedProps {
  groupedMessagesCount: number;
  isGroupedMessages: boolean;
  messagesCount: number;
  name: string;
}

export default Vue.extend({
  name: 'DetailsMessagesGrouped',
  props: {
    detailsMessagesGroupedProps: { required: true } as PropOptions<DetailsMessagesGroupedProps>,
    onUnfoldThread: { required: true } as PropOptions<() => void>,
  },
  computed: {
    showGroupedMessages(): boolean {
      return (
        this.detailsMessagesGroupedProps.isGroupedMessages &&
        this.detailsMessagesGroupedProps.messagesCount > MIN_UNGROUPED_MESSAGES
      );
    },
  },
});
