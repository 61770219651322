
import Vue from 'vue';
import Alerts from '@/mixins/Alerts';
import DownloadFile from '@/mixins/DownloadFile';
import * as helpScout from '@/services/helpScout';
import { AuthUser } from '@/services/auth-user';
import { displayNameEnv, showDocumentUrl } from '@/config/config';
import { localStorageService, LOCAL_STORAGE_KEYS } from '@/services/local-storage';
import {
  addMessageToThreadOfProfessional,
  getMessagesThreadOfProfessional,
  getMessagesDetailsOfProfessionalByThreadId,
  setReadThreadById,
  closeThreadById,
} from './api';
import { MenuItem } from './components';
import {
  AnotherUser,
  AttachedFile,
  Chat,
  createEmptyDetailsThread,
  createEmptyOptionsStatusFilter,
  createNewThreadWithAnotherUser,
  MIN_UNGROUPED_MESSAGES,
  SummaryMessage,
  MIN_LETTERS_TO_SEARCH,
  createEmptyOwnUser,
} from './messaging.model';
import { mapMessageVmToApi } from './messaging.mapper';
import { searchThreads } from './messaging.business';
import MessagingComponent from './MessagingComponent.vue';
import { Pagination } from './api/api.model';
import { mapGetters } from 'vuex';

const getCreatePdf = () => import(/* webpackChunkName: "messaging" */ '@/services/pdf');

export default Vue.extend({
  name: 'MessagingContainer',
  title: `${displayNameEnv} - ${window.vm.$t('menu.myMessages')}`,
  components: { MessagingComponent },
  mixins: [Alerts, DownloadFile],
  data() {
    return {
      anotherUsers: [] as AnotherUser[],
      attachedFile: null as File,
      attachedFileName: '',
      isGroupedMessages: true,
      isLoadingThreadById: false,
      isPatient: null as boolean,
      isSaving: false,
      isSavingSuccess: false,
      isThreadsPanelLoading: false,
      isVisibleDialogNewThread: false,
      messageText: '',
      optionsStatusFilter: createEmptyOptionsStatusFilter(),
      ownUser: createEmptyOwnUser(),
      scrollSettings: {
        maxScrollbarLength: 160,
      },
      searchText: null as string,
      selectedItem: null as number,
      selectedMessagesThreadId: null as number,
      selectedStatusFilter: null as MenuItem,
      selectedThread: createEmptyDetailsThread(),
      showMessages: false,
      showPdf: false,
      showSplitAreaLeft: true,
      summaryMessages: [] as SummaryMessage[],
      threadId: this.$route.params.id,
      specialties: [],
      specialtySelected: 0,
      messagingPermission: false,
      threadListPollingTimer: null,
      selectedThreadPollingTimer: null,
      meta: {} as Pagination,
      page: 1,
      lastCallToListThreadsApi: 0,
      lastCallToGetSelectedThreadApi: 0,
    };
  },
  computed: {
    ...mapGetters({
      isEnabledChatV2Integration: 'app/getChatV2IntegrationFeatureFlag',
    }),

    showPoweredByDocline(): boolean {
      return this.$store.getters['app/showPoweredByDocline'];
    },
    filteredThreadsBySearch(): SummaryMessage[] {
      return this.searchText?.length >= MIN_LETTERS_TO_SEARCH
        ? searchThreads(this.summaryMessages, this.searchText)
        : this.summaryMessages;
    },
    filteredMessagesThread(): SummaryMessage[] {
      const filterMessagesByState = (message: SummaryMessage): boolean => {
        switch (this.selectedStatusFilter.type) {
          case 'news':
            return message.unreadMessagesCount > 0;
          case 'unsolved':
            return message.status === 'open';
          case 'solved':
            return message.status === 'close';
          default:
            return true;
        }
      };

      return this.filteredThreadsBySearch.filter(filterMessagesByState);
    },
    groupedMessagesCount(): number {
      return !this.isGroupedMessages || this.readMessagesCount === 0
        ? 0
        : this.readMessagesCount - MIN_UNGROUPED_MESSAGES;
    },
    unreadMessages(): Chat[] {
      return this.selectedThread.messages.filter(item => item.isRead === false);
    },
    messagesCount(): number {
      return this.selectedThread.messages.length;
    },
    readMessagesCount(): number {
      return this.messagesCount - this.unreadMessagesCount;
    },
    unreadMessagesCount(): number {
      return this.unreadMessages.length;
    },
  },
  created() {
    this.threadListPollingTimer = window.setInterval(() => {
      if (Date.now() - this.lastCallToListThreadsApi < 12000) {
        return;
      }

      this.getThreads();
    }, 15000);

    this.selectedThreadPollingTimer = window.setInterval(() => {
      if (Date.now() - this.lastCallToGetSelectedThreadApi < 8000) {
        return;
      }

      if (null === this.selectedMessagesThreadId) {
        return;
      }

      if (0 === this.selectedMessagesThreadId) {
        return;
      }

      if (false === this.showMessages) {
        return;
      }

      this.onLoadMessageThreadById(this.selectedMessagesThreadId, true);
    }, 10000);
  },
  async mounted() {
    this.onResetStatusFilter();
    this.getOwnUser();
    await this.onLoadMessagesThread();

    if (this.$route.name === 'MessagingCreate') {
      this.isVisibleDialogNewThread = true;
    } else if (this.$route.name === 'MessagingDetails') {
      if (this.threadId && this.summaryMessages.some(thread => thread.idThread === Number(this.threadId))) {
        await this.onLoadMessageThreadById(Number(this.threadId));
        this.updateSelectedThreadList(Number(this.threadId));
      } else {
        this.toastError(this.$t('mensajeria.not_exists_thread', { id: this.threadId }).toString());
        this.$router.push({ name: 'Mensajeria' });
      }
    }

    this.$nextTick(() => {
      helpScout.hideContainer();
    });
  },

  beforeDestroy() {
    window.clearInterval(this.threadListPollingTimer);
    window.clearInterval(this.selectedThreadPollingTimer);
    helpScout.showContainer();
  },

  methods: {
    async getThreads() {
      this.lastCallToListThreadsApi = Date.now();
      const userAuth = localStorageService.getItem<AuthUser>(LOCAL_STORAGE_KEYS.AUTH_EHEALTH);
      this.isPatient = userAuth?.isPatient;
      if (userAuth.isPatient) {
        // this.messageList = await getMessagesThread(userAuth.user, 100, 1);
      } else {
        try {
          const { summaryMessages, anotherUsers, response } = await getMessagesThreadOfProfessional(
            this.selectedStatusFilter.type,
            this.searchText,
            this.page
          );
          this.summaryMessages = summaryMessages;
          this.anotherUsers = anotherUsers;
          this.specialties = response.data.specialties;
          this.messagingPermission = response.data.messagingPermission;
          this.meta = response.data.meta;
        } catch (error: any) {
          error?.response?.data?.detail
            ? this.toastError(error.response.data.detail)
            : this.toastError(this.$t('errors.try_again') as string);
        }
      }
    },

    async onLoadMessagesThread(silent = false) {
      this.isThreadsPanelLoading = !silent;
      await this.getThreads();
      this.isThreadsPanelLoading = false;
    },

    async onLoadMessageThreadById(id: number, silent = false) {
      this.lastCallToGetSelectedThreadApi = Date.now();

      try {
        (this.$route.name !== 'MessagingDetails' || this.$route.params?.id !== id.toString()) &&
          this.$router.push({
            name: 'MessagingDetails',
            params: { id: id.toString() },
          });

        if (this.$vuetify.breakpoint.mobile) {
          this.showSplitAreaLeft = false;
        }

        this.isLoadingThreadById = !silent;
        this.selectedMessagesThreadId = Number(id);
        this.showMessages = true;

        const userAuth = localStorageService.getItem<AuthUser>(LOCAL_STORAGE_KEYS.AUTH_EHEALTH);
        this.selectedThread = await getMessagesDetailsOfProfessionalByThreadId(
          userAuth.user,
          Number(id),
          this.anotherUsers
        );
        if (!silent) {
          this.isGroupedMessages = true;
        }
      } catch (error: any) {
        error?.response?.data?.detail
          ? this.toastError(error.response.data.detail)
          : this.toastError(this.$t('errors.try_again') as string);
      } finally {
        this.isLoadingThreadById = false;
      }
    },
    updateSelectedThreadList(threadId: number) {
      this.selectedItem = this.filteredMessagesThread.findIndex(thread => thread.idThread === threadId);
    },
    async onReadThreadById(threadId: number) {
      const userAuth = localStorageService.getItem<AuthUser>(LOCAL_STORAGE_KEYS.AUTH_EHEALTH);

      const isRead = await setReadThreadById(
        userAuth.user.id,
        userAuth.isPatient ? 'patient' : 'professional',
        threadId
      );

      this.selectedThread.messages = this.selectedThread.messages.map(message => {
        message.isRead = isRead;
        return message;
      });
    },
    onResetAttachedFile() {
      this.attachedFile = null;
      this.attachedFileName = '';
    },
    onUpdateMessageText(value: string) {
      this.messageText = value;
    },
    onChangeStatusFilter(option: MenuItem) {
      this.selectedStatusFilter = option;
      this.page = 1;
      this.getThreads();
    },
    onResetStatusFilter() {
      this.searchText = '';
      const requestThreads = null !== this.selectedStatusFilter;
      this.selectedStatusFilter = this.optionsStatusFilter.find(option => option.type === 'unsolved');
      this.page = 1;
      if (requestThreads) {
        this.getThreads();
      }
    },
    onChangeSelectedItem(id: number) {
      this.selectedItem = id;
    },
    onClosePanelRight() {
      this.onResetAttachedFile();
      this.onUpdateMessageText('');
      this.showMessages = false;
      this.selectedItem = null;

      if (this.$vuetify.breakpoint.mobile) {
        this.showSplitAreaLeft = true;
      }
    },
    async onOpenUrl(file: AttachedFile) {
      await (this as any).download({
        url: this.isEnabledChatV2Integration ? file.url : showDocumentUrl + '/' + file.id,
        name: file.name,
        type: file.type,
        open: true,
        openInNewTab: true,
        download: false,
      });
    },
    onChangePage(page: number) {
      this.page = page;
      this.getThreads();
    },
    onResetSearch() {
      this.searchText = '';
      this.getThreads();
    },
    onClickAttachedFile(id: number) {
      const file = this.selectedThread.attachedFiles.find(file => file.id === id);
      file && this.onOpenUrl(file);
    },
    onFileDragHover(evt: DragEvent) {
      evt.stopPropagation();
      evt.preventDefault();
    },
    onFileSelectHandler(evt: any) {
      this.onFileDragHover(evt);
      const files: FileList = evt.target.files || evt.dataTransfer.files;
      this.attachedFile = files[0];
      this.attachedFileName = this.attachedFile.name;
    },
    onUnfoldThread() {
      this.isGroupedMessages = false;
    },
    async onSendMessage() {
      try {
        this.isSaving = true;
        this.showMessages = true;

        const userAuth = localStorageService.getItem<AuthUser>(LOCAL_STORAGE_KEYS.AUTH_EHEALTH);

        const messageData = mapMessageVmToApi(
          userAuth.user.id,
          this.selectedMessagesThreadId,
          this.selectedThread.subject,
          this.specialtySelected,
          this.messageText,
          this.attachedFile,
          {
            name: this.selectedThread.anotherUser.name,
            rol: this.selectedThread.anotherUser.rol,
            to: this.selectedThread.anotherUser.id,
          }
        );

        const setThreadId = await addMessageToThreadOfProfessional(messageData, this.selectedMessagesThreadId === 0);
        await this.onLoadMessagesThread(true);
        this.$nextTick(async () => {
          this.selectedItem = this.summaryMessages.findIndex(({ idThread }) => idThread === Number(setThreadId));
          await this.onLoadMessageThreadById(Number(setThreadId), true);
        });

        this.messageText = '';
        this.onResetAttachedFile();
        this.isSavingSuccess = true;

        setTimeout(() => {
          this.isSavingSuccess = false;
        }, 3000);
      } catch (error: any) {
        error?.response?.data?.detail
          ? this.toastError(error.response.data.detail)
          : this.toastError(this.$t('errors.try_again') as string);
      } finally {
        this.isSaving = false;
      }
    },
    onCreateChat(recipientId: number, subject: string, specialtySelected: number, rol: string) {
      this.selectedMessagesThreadId = 0;
      this.specialtySelected = specialtySelected;

      if (this.$vuetify.breakpoint.mobile) {
        this.showSplitAreaLeft = false;
      }

      this.isLoadingThreadById = true;
      this.selectedMessagesThreadId = 0;
      this.showMessages = true;

      const anotherUser = this.anotherUsers.find(user => user.id === recipientId && user.rol === rol);
      this.selectedThread = createNewThreadWithAnotherUser(anotherUser, subject);

      this.isGroupedMessages = false;
      this.isLoadingThreadById = false;
    },
    onShowNewThreadDialog(visible: boolean) {
      this.isVisibleDialogNewThread = visible;
    },
    async onCloseThread() {
      const userAuth = localStorageService.getItem<AuthUser>(LOCAL_STORAGE_KEYS.AUTH_EHEALTH);
      await closeThreadById(userAuth.user.id, this.selectedMessagesThreadId);
      await this.onLoadMessagesThread();
      await this.onLoadMessageThreadById(this.selectedMessagesThreadId);
    },
    onDownloadPDF(value: boolean, el?: Element) {
      this.showPdf = value;
      if (value && el) {
        this.$nextTick(async () => {
          const lazyCreatePdf = await getCreatePdf();
          lazyCreatePdf.createPdf.init(el);
          this.showPdf = false;
        });
      }
    },
    onChangeSearchText(value: string) {
      this.searchText = null === value ? '' : value;
      setTimeout(() => {
        if (this.searchText === value) {
          this.page = 1;
          this.getThreads();
        }
      }, 500);
    },
    getOwnUser() {
      const userAuth = localStorageService.getItem<AuthUser>(LOCAL_STORAGE_KEYS.AUTH_EHEALTH);
      this.ownUser = {
        name: `${userAuth.user.name} ${userAuth.user.surname}`,
        rol: userAuth.isPatient ? 'patient' : 'professional',
      };
    },
  },
});
