import { AuthUser } from '@/services/auth-user'; // TODO: Pending to use authUser service
import {
  allMensajeriaUrl,
  cerrarMensajeUrl,
  getHeaderMultimedia,
  mensajeLeidoUrl,
  sendMensajeUrl,
} from '@/config/config';
import { http } from '@/core/api';
import {
  mapProfessionalThreadsListApiToVm,
  mapProfessionalDetailsThreadApiToVm,
  mapProfessionalAnotherUsersApiToVm,
} from '../messaging.mapper';
import { searchThreadById, sortArrayAlphabetically } from '../messaging.business';
import * as vm from '../messaging.model';
import { GetMessagesOfThread, GetMessaging, MessageFormData, ResponseSendData } from './api.model';

export const getMessagesThreadOfProfessional = async (type: string, filter: string, page: number) => {
  try {
    const response = await http.get<GetMessaging>(
      allMensajeriaUrl + '?type=' + type + '&filter=' + filter + '&page=' + page
    );
    if (response.status === 200) {
      const summaryMessages = mapProfessionalThreadsListApiToVm(response.data);
      summaryMessages.sort(function(a, b) {
        return Number(new Date(b.lastMessageAt)) - Number(new Date(a.lastMessageAt));
      });
      let anotherUsers = mapProfessionalAnotherUsersApiToVm(response.data);
      anotherUsers = sortArrayAlphabetically(anotherUsers, 'name');
      return Promise.resolve({ anotherUsers, summaryMessages, response });
    } else {
      throw new Error('Se ha producido un error al obtener los datos');
    }
  } catch (error) {
    throw new Error(`Se ha producido un error al obtener los datos: ${error}`);
  }
};

export const getMessagesDetailsOfProfessionalByThreadId = async (
  user: AuthUser['user'],
  threadId: number,
  anotherUsers: vm.AnotherUser[]
): Promise<vm.DetailsThread> => {
  try {
    const response = await http.get<GetMessagesOfThread>(allMensajeriaUrl + '/' + threadId);
    if (response.status === 200) {
      const { thread, status } = searchThreadById(response.data, threadId);
      const threadVm = mapProfessionalDetailsThreadApiToVm(user, thread, status, anotherUsers);
      return Promise.resolve(threadVm);
    }
  } catch (error) {
    throw new Error(`Se ha producido un error al obtener los datos: ${error}`);
  }
};

export const addMessageToThreadOfProfessional = async (
  message: MessageFormData,
  isNewThread?: boolean
): Promise<number> => {
  try {
    const formData = new FormData();
    formData.append('file', message.file);
    formData.append('id', message.id.toString()); // Author id
    formData.append('texto', message.texto);
    formData.append('tiene_adjunto', String(message.tiene_adjunto));
    formData.append('mensajeria_id', (message.mensajeria_id as unknown) as string); // If the thread is new, this id will be '0'
    formData.append('is_interconsultation', String(message.is_interconsultation));
    formData.append('motivo', message.motivo);
    formData.append('specialtyIdSelected', (message.specialtySelected as unknown) as string);
    message.to && formData.append('to', message.to.toString());
    message.to_name && formData.append('to_name', message.to_name);

    const response = await http.post<ResponseSendData>(sendMensajeUrl, formData, { headers: getHeaderMultimedia() });
    if (response.status === 200 && (response.data.estado === '1' || response.data.estado === '2')) {
      return Promise.resolve(isNewThread ? response.data.mensaje.id : response.data.mensaje.mensajeria_id);
    }
    return Promise.reject('An error has ocurred');
  } catch (error) {
    throw new Error(`Se ha producido un error al enviar los datos: ${error}`);
  }
};

export const setReadThreadById = async (userId: number, rol: vm.RolUser, threadId: number): Promise<boolean> => {
  try {
    const params = {
      id: userId,
      rol,
      mensajeria_id: threadId,
    };

    const response = await http.post(mensajeLeidoUrl, params);
    return response.status === 200 && response.data.estado === 1 ? Promise.resolve(true) : Promise.resolve(false);
  } catch (error) {
    throw new Error(`Se ha producido un error: ${error}`);
  }
};

export const closeThreadById = async (userId: number, threadId: number): Promise<boolean> => {
  try {
    const response = await http.post(cerrarMensajeUrl, { id: userId, mensajeria_id: threadId });
    if (response.status === 200 && response.data.estado === '1') {
      return Promise.resolve(true);
    }

    return Promise.resolve(false);
  } catch (error) {
    throw new Error(`Se ha producido un error: ${error}`);
  }
};
