
import Vue, { PropOptions } from 'vue';
import { AttachedFile } from '../messaging.model';

export interface DetailsToolbarProps {
  attachedFiles: AttachedFile[];
  attachedFilesCount: number;
  isClose: boolean;
  subject: string;
}

export default Vue.extend({
  name: 'DetailsToolbar',
  props: {
    onClosePanelRight: { required: true, type: Function } as PropOptions<() => void>,
    onCloseThread: { required: true, type: Function } as PropOptions<() => void>,
    onDownloadPDF: { required: true, type: Function } as PropOptions<(value: boolean) => void>,
    onOpenUrl: { required: true, type: Function } as PropOptions<(file: AttachedFile) => void>,
    toolbarProps: { required: true, type: Object } as PropOptions<DetailsToolbarProps>,
  },
});
