import { i18n } from '../../../engine/framework/main';
import { MenuItem } from './components';

export const MIN_UNGROUPED_MESSAGES = 3;
export const MIN_LETTERS_TO_SEARCH = 1;
export const FORMAT_PARSE_DATE = 'DD-MM-YYYY[T]HH:mm:ss';

export type Status = 'open' | 'close' | 'pending';
export type WrittenBy = 'ownUser' | 'anotherUser';
export type RolUser = 'patient' | 'professional';

export interface User {
  collegiateNumber?: string;
  id: number;
  image: string;
  name: string;
  rol: RolUser;
  specialties?: string[];
  title?: string;
}

export type OwnUser = Pick<User, 'name' | 'rol'>;

// Summary message thread (left panel)
export type SummaryUser = User;

export interface SummaryMessage {
  anotherUser: SummaryUser;
  hasAttachedFiles: boolean;
  idThread: number;
  lastMessageAt: string;
  status: Status;
  title: string;
  unreadMessagesCount: number;
  attachedFiles?: AttachedFile[];
}

// Details chat (right panel)
export interface DetailsThread {
  anotherUser: User;
  attachedFiles: AttachedFile[];
  messages: Chat[];
  status: Status;
  subject: string;
}

export interface AttachedFile {
  id: number;
  name: string;
  type: string;
  url: string;
}

export type DetailsChatUser = Omit<User, 'rol'> & { rol?: RolUser };

export interface Chat {
  attachedFiles: AttachedFile[];
  id: number;
  isRead: boolean;
  sendAt: string;
  text: string;
  user: DetailsChatUser;
  writtenBy: WrittenBy;
}

export type AnotherUser = User;

export const createEmptyDetailsThread = (): DetailsThread => ({
  anotherUser: {
    collegiateNumber: '',
    id: null,
    image: '',
    name: '',
    rol: null,
    specialties: [],
    title: '',
  },
  attachedFiles: [],
  messages: [],
  status: 'pending',
  subject: '',
});

export const createEmptyOptionsStatusFilter = (): MenuItem[] => [
  { title: i18n.t('messages.unsolved') as string, type: 'unsolved' },
  { title: i18n.t('messages.new') as string, type: 'news' },
  { title: i18n.t('messages.solved') as string, type: 'solved' },
];

export const createNewThreadWithAnotherUser = (anotherUser: User, subject: string): DetailsThread => ({
  anotherUser: {
    id: anotherUser.id,
    image: anotherUser.image,
    name: anotherUser.name,
    rol: anotherUser.rol,
    collegiateNumber: anotherUser.collegiateNumber,
    specialties: anotherUser.specialties,
    title: anotherUser.title,
  },
  attachedFiles: [],
  messages: [],
  status: 'pending',
  subject,
});

export const createEmptyOwnUser = (): OwnUser => ({ name: '', rol: null });
