var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('messaging-component',_vm._b({},'messaging-component',{
    attachedFileName: _vm.attachedFileName,
    groupedMessagesCount: _vm.groupedMessagesCount,
    isGroupedMessages: _vm.isGroupedMessages,
    isLoadingThreadById: _vm.isLoadingThreadById,
    isSaving: _vm.isSaving,
    isSavingSuccess: _vm.isSavingSuccess,
    isThreadsPanelLoading: _vm.isThreadsPanelLoading,
    isVisibleDialogNewThread: _vm.isVisibleDialogNewThread,
    messageText: _vm.messageText,
    onChangeSearchText: _vm.onChangeSearchText,
    onChangeSelectedItem: _vm.onChangeSelectedItem,
    onChangeStatusFilter: _vm.onChangeStatusFilter,
    onClickAttachedFile: _vm.onClickAttachedFile,
    onClosePanelRight: _vm.onClosePanelRight,
    onCloseThread: _vm.onCloseThread,
    onCreateChat: _vm.onCreateChat,
    onDownloadPDF: _vm.onDownloadPDF,
    onFileDragHover: _vm.onFileDragHover,
    onFileSelectHandler: _vm.onFileSelectHandler,
    onLoadMessageThreadById: _vm.onLoadMessageThreadById,
    onOpenUrl: _vm.onOpenUrl,
    onReadThreadById: _vm.onReadThreadById,
    onResetAttachedFile: _vm.onResetAttachedFile,
    onResetStatusFilter: _vm.onResetStatusFilter,
    onSendMessage: _vm.onSendMessage,
    onShowNewThreadDialog: _vm.onShowNewThreadDialog,
    onUnfoldThread: _vm.onUnfoldThread,
    onUpdateMessageText: _vm.onUpdateMessageText,
    optionsStatusFilter: _vm.optionsStatusFilter,
    ownUser: _vm.ownUser,
    recipientsOptions: _vm.anotherUsers,
    scrollSettings: _vm.scrollSettings,
    searchText: _vm.searchText,
    selectedItem: _vm.selectedItem,
    selectedStatusFilter: _vm.selectedStatusFilter,
    selectedThread: _vm.selectedThread,
    showMessages: _vm.showMessages,
    showPdf: _vm.showPdf,
    showPoweredByDocline: _vm.showPoweredByDocline,
    showSplitAreaLeft: _vm.showSplitAreaLeft,
    summaryThreads: _vm.filteredMessagesThread,
    specialties: _vm.specialties,
    messagingPermission: _vm.messagingPermission,
    meta: _vm.meta,
    onChangePage: _vm.onChangePage,
    onResetSearch: _vm.onResetSearch,
  },false))
}
var staticRenderFns = []

export { render, staticRenderFns }