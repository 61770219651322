var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',{staticStyle:{"position":"relative"},attrs:{"tile":"","flat":"","height":"100%"}},[_c('details-toolbar',_vm._b({},'details-toolbar',{ toolbarProps: _vm.toolbarProps, onClosePanelRight: _vm.onClosePanelRight, onOpenUrl: _vm.onOpenUrl, onCloseThread: _vm.onCloseThread, showPdf: _vm.showPdf, onDownloadPDF: _vm.onDownloadPDF },false)),(_vm.isLoadingThreadById)?_c('div',{staticClass:"pa-5 text-center"},[_c('v-progress-circular',{attrs:{"width":"2","indeterminate":"","color":"primary"}})],1):_vm._e(),_c('details-panel-bridge',{attrs:{"is-thread-close":_vm.isThreadClose},scopedSlots:_vm._u([(!_vm.isLoadingThreadById && _vm.selectedThread)?{key:"details-chat",fn:function(){return [_c('vue-perfect-scrollbar',{staticClass:"messages-tread--scroll px-0 pb-5 body-1 animate__animated animate__fadeIn",style:('overflow-y:auto;height:100%'),attrs:{"settings":_vm.scrollSettings}},[_c('details-contact-info',{attrs:{"contact-info-props":_vm.contactInfoProps}}),_c('details-messages-grouped',{attrs:{"details-messages-grouped-props":_vm.detailsMessagesGroupedProps,"on-unfold-thread":_vm.onUnfoldThread}}),_vm._l((_vm.selectedThread.messages),function(message,index){return _c('details-chat',{key:message.id,class:{
            lastMessage: index === _vm.selectedThread.messages.length - 1,
          },attrs:{"details-chat-props":_vm.detailsChatProps(message, index),"on-click-attached-file":_vm.onClickAttachedFile,"show-pdf":_vm.showPdf}})})],2)]},proxy:true}:null,{key:"details-chat-editor",fn:function(){return [(!_vm.isLoadingThreadById && !_vm.isThreadClose)?_c('details-chat-editor',_vm._b({},'details-chat-editor',{
          attachedFileName: _vm.attachedFileName,
          isSaving: _vm.isSaving,
          isSavingSuccess: _vm.isSavingSuccess,
          messageText: _vm.messageText,
          onFileDragHover: _vm.onFileDragHover,
          onFileSelectHandler: _vm.onFileSelectHandler,
          onResetAttachedFile: _vm.onResetAttachedFile,
          onSendMessage: _vm.onSendMessage,
          onUpdateMessageText: _vm.onUpdateMessageText,
          selectedThread: _vm.selectedThread,
        },false)):_vm._e()]},proxy:true}],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }