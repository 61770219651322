import { AuthUser } from '@/services/auth-user';
import * as api from './api/api.model';
import * as vm from './messaging.model';

const existThreadWithAnotherUser = (
  { rol, receiver_id }: api.Thread,
  patients: api.User[],
  professionals: api.Professional[]
): boolean =>
  rol === 'professional'
    ? professionals.some(({ id }) => id === receiver_id)
    : patients.some(({ value }) => value === receiver_id);

const mapAttachedFileApiToVm = (file: api.AttachedFile): vm.AttachedFile =>
  file && {
    id: file.id,
    name: file.nombreFile,
    type: file.type || file.fileType,
    url: file.file,
  };

const mapMessageApiToVm = (
  userId: number,
  message: api.Message,
  attachedFilesInThread: api.AttachedFile[] = [],
  ownUser: vm.DetailsChatUser,
  anotherUser: vm.DetailsChatUser,
  isInterconsultation: boolean
): vm.Chat => {
  const getUser = (emitId: number, senderKey: number) => {
    if (isInterconsultation) {
      return emitId === userId ? ownUser : anotherUser;
    }

    return senderKey % 2 === 0 ? ownUser : anotherUser;
  };
  const getWrittenBy = (emitId: number, senderKey: number) => {
    if (isInterconsultation) {
      return emitId === userId ? 'ownUser' : 'anotherUser';
    }

    return senderKey % 2 === 0 ? 'ownUser' : 'anotherUser';
  };

  return {
    id: message.id,
    sendAt: message.creation_date,
    text: message.texto,
    user: getUser(message.emisor_id, message.emisor),
    writtenBy: getWrittenBy(message.emisor_id, message.emisor),
    isRead: Boolean(message.leido),
    attachedFiles: attachedFilesInThread.filter(file => file.messageId === message.id).map(mapAttachedFileApiToVm),
  };
};

export const mapProfessionalThreadApiToVm = (
  thread: api.Thread,
  patients: api.User[],
  professionals: api.Professional[],
  status: api.StatusThread
): vm.SummaryMessage => {
  if (!thread) return null;

  const isProfessional = (user: api.User | api.Professional): user is api.Professional =>
    (user as api.Professional).collegiate !== undefined;

  const userThread =
    thread.rol === 'professional'
      ? professionals.find(({ id }) => id === thread.receiver_id)
      : patients.find(({ value }) => value === thread.receiver_id);

  if (!userThread) return null;

  const anotherUser: vm.SummaryUser = isProfessional(userThread)
    ? {
        collegiateNumber: userThread.collegiate,
        id: userThread.id,
        image: userThread.avatar,
        name: userThread.name,
        rol: 'professional',
        specialties: userThread.specialties.map(({ name }) => name),
        title: userThread.title,
      }
    : {
        id: userThread.value,
        image: '',
        name: userThread.label,
        rol: 'patient',
      };
  const lastMessageAt =
    typeof thread.mensajes[thread.mensajes.length - 1] !== 'undefined'
      ? thread.mensajes[thread.mensajes.length - 1].creation_date
      : thread.creation_date;

  return {
    title: thread.mensaje,
    idThread: thread.id,
    lastMessageAt: lastMessageAt,
    status: status === 'abiertos' ? 'open' : status === 'cerrados' ? 'close' : 'pending',
    hasAttachedFiles: Boolean(thread.adjuntos.length),
    anotherUser,
    unreadMessagesCount: thread.unreadMessagesCount,
  };
};

export const mapProfessionalThreadsListApiToVm = (messaging: api.GetMessaging): vm.SummaryMessage[] => {
  const sortedFactor: api.StatusThread[] = ['pendientes', 'abiertos', 'cerrados'];
  return sortedFactor.flatMap(status =>
    messaging.threads[status]
      .filter(thread => thread && existThreadWithAnotherUser(thread, messaging.patients, messaging.professionals))
      .flatMap(thread => mapProfessionalThreadApiToVm(thread, messaging.patients, messaging.professionals, status))
      .reverse()
  );
};

export const mapProfessionalDetailsThreadApiToVm = (
  authUser: AuthUser['user'],
  thread: api.Thread,
  status: api.StatusThread,
  anotherUsers: vm.AnotherUser[]
): vm.DetailsThread => {
  const ownUser: vm.DetailsChatUser = {
    id: authUser.id,
    name: `${authUser.name} ${authUser.surname}`,
    image: authUser.photo,
  };

  const foundUser = anotherUsers.find(user => user.id === thread.receiver_id && user.rol === thread.rol);
  const anotherUser: vm.DetailsChatUser = {
    id: thread.receiver_id,
    name: foundUser.name,
    image: foundUser.image,
    collegiateNumber: foundUser.collegiateNumber,
    specialties: foundUser.specialties ?? [],
  };

  return {
    anotherUser: {
      ...anotherUser,
      rol: thread.rol === 'professional' ? 'professional' : 'patient',
    },
    attachedFiles: thread.adjuntos.map(mapAttachedFileApiToVm),
    messages: thread.mensajes.map(message =>
      mapMessageApiToVm(
        authUser.professional_id,
        message,
        thread.adjuntos,
        ownUser,
        anotherUser,
        thread.is_interconsultation
      )
    ),
    status: status === 'abiertos' ? 'open' : status === 'cerrados' ? 'close' : 'pending',
    subject: thread.mensaje,
  };
};

export const mapProfessionalAnotherUsersApiToVm = ({ patients, professionals }: api.GetMessaging): vm.AnotherUser[] => {
  const patientsVm: vm.AnotherUser[] = patients.map(({ label, value }) => ({
    rol: 'patient',
    id: value,
    image: '',
    name: label,
  }));

  const professionalsVm: vm.AnotherUser[] = professionals.map(
    ({ id, name, avatar, collegiate, specialties, title }) => ({
      rol: 'professional',
      collegiateNumber: collegiate,
      id,
      image: avatar,
      name,
      specialties: specialties.map(({ name }) => name),
      title,
    })
  );

  return [...patientsVm, ...professionalsVm];
};

//#region Vm to Api
export const mapMessageVmToApi = (
  userId: number,
  threadId: number,
  subject: string,
  specialtySelected: number,
  textMessage: string,
  file?: File,
  toUser?: {
    to: number;
    name: string;
    rol: vm.RolUser;
  }
): api.MessageFormData =>
  (textMessage || file) && {
    file: file,
    id: userId,
    mensajeria_id: threadId,
    motivo: subject,
    specialtySelected: specialtySelected || 0,
    texto: textMessage || '',
    tiene_adjunto: !!file,
    to: toUser?.to,
    to_name: toUser?.name,
    is_interconsultation: toUser.rol === 'professional',
  };
//#endregion
