import * as api from './api/api.model';
import * as vm from './messaging.model';

export const searchThreadById = (
  messaging: api.GetMessagesOfThread,
  threadId: number
): {
  status: api.StatusThread;
  thread: api.Thread;
} => {
  let status: api.StatusThread;
  let thread: api.Thread;

  if (messaging.thread.id === threadId) {
    status = messaging.status;
    thread = messaging.thread;
  }

  return { status, thread };
};

export const sortArrayAlphabetically = <T, M extends keyof T>(array: T[], field: M): T[] =>
  Array.isArray(array) &&
  array.sort((a, b) =>
    a[field].toString().toLowerCase() < b[field].toString().toLowerCase()
      ? -1
      : a[field].toString().toLowerCase() > b[field].toString().toLowerCase()
      ? 1
      : 0
  );

const normalizeString = (str: string): string => str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

export const searchThreads = (threads: vm.SummaryMessage[], searchText: string): vm.SummaryMessage[] => {
  const ignoreIrrelevantWords = [
    'de',
    'of',
    'el',
    'la',
    'the',
    'y',
    'o',
    'and',
    'or',
    'para',
    'for',
    'to',
    'en',
    'in',
    'on',
  ];

  const searchTextArray = normalizeString(searchText)
    .trim()
    .toLowerCase()
    .split(' ')
    .filter(str => Boolean(str) && !ignoreIrrelevantWords.includes(str));

  return threads.filter(thread =>
    searchTextArray.every(str => {
      const arrayTarget =
        normalizeString(thread.title).toLowerCase() + normalizeString(thread.anotherUser.name).toLowerCase();
      return arrayTarget.includes(str);
    })
  );
};
