import axios from 'axios';
import { apiDomain } from '@/config/config';
import { getHeader } from './api.helpers';

export const http = axios.create({
  baseURL: apiDomain,
});

http.interceptors.request.use(
  config => {
    config.headers = getHeader() as any;
    return config;
  },
  error => Promise.reject(error)
);

http.interceptors.response.use(
  response => response,
  error => {
    /*
     * Here you can add a central error handling mechanism
     */
    console.warn('Error API:', { error });
    return Promise.reject(error);
  }
);
