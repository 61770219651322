
import Vue, { PropType } from 'vue';
import { TheFooter } from '@/common/components';
import { SummaryMessage } from '../messaging.model';
import ThreadsItem from './ThreadsItem.vue';
import { ScrollSettings, MenuItem } from './threads-panel.model';

export default Vue.extend({
  name: 'ThreadsResult',
  components: { TheFooter, ThreadsItem },
  props: {
    appNavHeight: { type: Number, required: true },
    onChangeSelectedItem: { type: Function as PropType<(item: number) => void>, required: true },
    onLoadMessageThreadById: { type: Function as PropType<(id: SummaryMessage['idThread']) => void>, required: true },
    onReadThreadById: { type: Function as PropType<(threadId: number) => void>, required: true },
    onResetStatusFilter: { type: Function as PropType<() => void>, required: true },
    scrollSettings: { type: Object as PropType<ScrollSettings>, required: true },
    selectedItem: { type: Number, default: null },
    selectedStatusFilter: { type: Object as PropType<MenuItem>, default: null },
    showPoweredByDocline: { type: Boolean, required: true },
    summaryThreads: { type: Array as PropType<SummaryMessage[]>, required: true },
    meta: { type: Object, required: true },
    onChangePage: { type: Function as PropType<(page: number) => void>, required: true },
  },
});
