var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticStyle:{"height":"100%"}},[_c('threads-toolbar',_vm._b({},'threads-toolbar',{
      foldToolbar: _vm.foldToolbar,
      isVisibleDialogNewThread: _vm.isVisibleDialogNewThread,
      onChangeSearchText: _vm.onChangeSearchText,
      onChangeStatusFilter: _vm.onChangeStatusFilter,
      onCreateChat: _vm.onCreateChat,
      onShowNewThreadDialog: _vm.onShowNewThreadDialog,
      optionsStatusFilter: _vm.optionsStatusFilter,
      recipientsOptions: _vm.recipientsOptions,
      searchText: _vm.searchText,
      selectedStatusFilter: _vm.selectedStatusFilter,
      specialties: _vm.specialties,
      messagingPermission: _vm.messagingPermission,
      onResetSearch: _vm.onResetSearch,
    },false)),(_vm.isThreadsPanelLoading)?_c('div',{staticClass:"pa-5 text-center"},[_c('v-progress-circular',{attrs:{"width":"2","indeterminate":"","color":"primary"}})],1):_vm._e(),(!_vm.isThreadsPanelLoading)?_c('threads-result',_vm._b({},'threads-result',{
      appNavHeight: _vm.appNavHeight,
      onChangeSelectedItem: _vm.onChangeSelectedItem,
      onLoadMessageThreadById: _vm.onLoadMessageThreadById,
      onReadThreadById: _vm.onReadThreadById,
      onResetStatusFilter: _vm.onResetStatusFilter,
      scrollSettings: _vm.scrollSettings,
      selectedItem: _vm.selectedItem,
      selectedStatusFilter: _vm.selectedStatusFilter,
      showPoweredByDocline: _vm.showPoweredByDocline,
      summaryThreads: _vm.summaryThreads,
      meta: _vm.meta,
      onChangePage: _vm.onChangePage,
    },false)):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }