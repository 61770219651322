
import Vue, { PropType } from 'vue';
import { SummaryMessage } from '../messaging.model';
import ThreadsToolbar from './ThreadsToolbar.vue';
import ThreadsResult from './ThreadsResult.vue';
import { Recipient } from './ThreadsToolbarMessageDialogItemList.vue';
import { MenuItem, MessageThread, ScrollSettings } from './threads-panel.model';

export default Vue.extend({
  name: 'ThreadsPanel',
  components: { ThreadsToolbar, ThreadsResult },
  props: {
    appNavHeight: { type: Number, required: true },
    foldToolbar: { type: Boolean, required: true },
    isThreadsPanelLoading: { type: Boolean, required: true },
    isVisibleDialogNewThread: { type: Boolean, required: true },
    onChangeSearchText: { type: Function as PropType<(value: string) => void>, required: true },
    onChangeSelectedItem: { type: Function as PropType<(item: number) => void>, required: true },
    onChangeStatusFilter: { type: Function as PropType<(item: MenuItem) => void>, required: true },
    onCreateChat: {
      type: Function as PropType<
        (recipientId: number, subject: string, specialtySelected: number, rol: string) => void
      >,
      required: true,
    },
    onLoadMessageThreadById: { type: Function as PropType<(id: MessageThread['id']) => void>, required: true },
    onReadThreadById: { type: Function as PropType<(threadId: number) => void>, required: true },
    onResetStatusFilter: { type: Function as PropType<() => void>, required: true },
    onShowNewThreadDialog: { type: Function as PropType<(visible: boolean) => void>, required: true },
    optionsStatusFilter: { type: Array as PropType<MenuItem[]>, required: true },
    recipientsOptions: { type: Array as PropType<Recipient[]>, required: true },
    scrollSettings: { type: Object as PropType<ScrollSettings>, required: true },
    searchText: { type: String, default: '' },
    selectedItem: { type: Number, default: null },
    selectedStatusFilter: { type: Object as PropType<MenuItem>, default: null },
    showPoweredByDocline: { type: Boolean, required: true },
    showSplitAreaLeft: { type: Boolean, required: true },
    splitAreaLeftSize: { type: Number, required: true },
    summaryThreads: { type: Array as PropType<SummaryMessage[]>, required: true },
    specialties: { type: Array, required: true },
    messagingPermission: { type: Boolean, required: true },
    meta: { type: Object, required: true },
    onChangePage: { type: Function as PropType<(page: number) => void>, required: true },
    onResetSearch: { type: Function as PropType<() => void>, required: true },
  },
});
